import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Button, Card, CardGrid, Div, FormItem, FormLayout, Group, Header, IconButton, Input, Panel, PanelHeader, SegmentedControl, SimpleCell } from '@vkontakte/vkui';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Address, BOC, Builder, Coins } from 'ton3';
import { Address as TonAddress } from 'ton';
import { Icon28ChevronLeftOutline, Icon28RefreshOutline } from '@vkontakte/icons';
import { Link } from 'react-router-dom';
import { DeLabUtils } from '../../../logic/utils';
import { TokenWallet } from '../../../logic/jetton';
import { FarmBlock } from '../block';
export const Farm = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    const [valueStake, setValueStake] = React.useState('');
    const [farm, setFarm] = React.useState(undefined);
    const [type, setType] = React.useState(0);
    const [jettons, setJettons] = React.useState(undefined);
    const [keyReload, setKeyReload] = React.useState(0);
    const { idfarm } = useParams();
    const [addressFarm, setAddressFarm] = React.useState(undefined);
    const isDesktop = window.innerWidth >= 1000;
    function checkAddress(text) {
        try {
            const address = TonAddress.parseFriendly(text).address.toString();
            return !!address;
        }
        catch (e) {
            return false;
        }
    }
    async function sendJetton(jettonWallet, to, amountJetton) {
        if (props.DeLabConnector.address) {
            const transJetton = {
                queryId: 1,
                tokenAmount: new Coins(Number(amountJetton)),
                fwdAmount: new Coins(0.5),
                to: new Address(to),
                responseAddress: new Address(props.DeLabConnector.address)
            };
            const boc = TokenWallet.buildTransferMessage(transJetton);
            const base64 = BOC.toBase64Standard(boc);
            console.log(base64);
            const trans = {
                to: jettonWallet,
                value: '700000000',
                payload: base64
            };
            const dataTx2 = await props.sendTransaction(trans);
            console.log(dataTx2);
            // setDataTx(dataTx2)
        }
    }
    async function sendTon(to, body) {
        const trans = {
            to,
            value: '700000000',
            payload: body
        };
        const dataTx2 = await props.sendTransaction(trans);
        console.log(dataTx2);
    }
    async function stakeFarm(jettonWallet) {
        if (props.DeLabConnector.address && idfarm) {
            sendJetton(jettonWallet, idfarm, valueStake);
        }
    }
    async function unstakeFarm() {
        if (props.DeLabConnector.address && idfarm) {
            const body = new Builder()
                .storeUint(0xe2, 32)
                .storeUint(~~(Date.now() / 1000), 64)
                .storeCoins(new Coins(valueStake))
                .cell();
            sendTon(idfarm, BOC.toBase64Standard(body));
            // sendJetton(jettonWallet, idfarm, valueStake)
        }
    }
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
            if (idfarm && checkAddress(idfarm))
                setAddressFarm(idfarm);
            // returnDataFarm(idfarm)
            console.log(idfarm);
        }
    }, [idfarm]);
    return (_jsxs(Panel, { id: props.id, children: [_jsx(PanelHeader, { className: "delab-fix-header" }), _jsx(Group, { children: addressFarm
                    ? _jsx(Div, { children: _jsxs("div", { children: [_jsxs(Div, { className: "delab-header-block", style: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' }, children: [_jsxs("div", { style: { display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }, children: [_jsx(Link, { to: "/farms", children: _jsx(IconButton, { children: _jsx(Icon28ChevronLeftOutline, {}) }) }), _jsxs(Header, { children: ["Farm ", jettons?.B.metadata.name] })] }), _jsx(IconButton, { onClick: () => setKeyReload(keyReload + 1), children: _jsx(Icon28RefreshOutline, {}) })] }), _jsx(CardGrid, { size: "l", children: _jsx(FarmBlock, { DeLabConnector: props.DeLabConnector, id: keyReload, setFarm: setFarm, setJettons: setJettons, idFarm: idfarm ?? '', activeLink: false }) }), jettons && farm && farm.metadata
                                    ? _jsxs(CardGrid, { size: isDesktop ? 'm' : 'l', children: [_jsx(Card, { children: farm.metadata
                                                    ? _jsxs(Div, { children: [_jsxs(SimpleCell, { disabled: true, indicator: DeLabUtils.fromNanoToString(farm.metadata.token_b_balance), children: [jettons.B.metadata.name, " locked"] }), _jsxs(SimpleCell, { disabled: true, indicator: DeLabUtils.fromNanoToString(farm.metadata.token_b_shared), children: [jettons.B.metadata.name, " shared"] }), _jsx(SimpleCell, { disabled: true, indicator: DeLabUtils.fromNanoToString(farm.metadata.reward_amount) + '/' + farm.metadata.reward_conf + 's', children: "Reward configuration" }), _jsx(Div, { children: _jsx(Button, { stretched: true, size: "l", target: '_blank', href: (process.env.NETWORK === 'mainnet' ? 'https://tonapi.io/account/' : 'https://testnet.tonapi.io/account/') + farm.addressContract, children: "Exlporer" }) })] })
                                                    : null }), _jsxs(Card, { children: [_jsxs(Div, { children: [_jsx(SimpleCell, { disabled: true, before: _jsx(Avatar, { src: jettons.A.metadata.image, size: 24 }), indicator: DeLabUtils.fromNanoToString(farm.balanceStaker) + ' ' + jettons.A.metadata.symbol, children: "Your stake" }), _jsx(SimpleCell, { disabled: true, before: _jsx(Avatar, { src: jettons.B.metadata.image, size: 24 }), indicator: farm.metadata
                                                                    ? DeLabUtils.fromNanoToString(farm.balanceReward) + ' ' + jettons.B.metadata.symbol
                                                                    : '0', children: "Accumulated reward" })] }), _jsx(Div, { children: _jsxs(FormLayout, { children: [_jsx(FormItem, { children: _jsx(SegmentedControl, { onChange: e => setType(Number(e) ?? 0), options: [
                                                                            {
                                                                                label: 'Stake',
                                                                                value: 0,
                                                                                'aria-label': 'Stake'
                                                                            },
                                                                            {
                                                                                label: 'Unstake',
                                                                                value: 1,
                                                                                'aria-label': 'Unstake'
                                                                            }
                                                                        ] }) }), _jsx(FormItem, { top: type
                                                                        ? 'Balance ' + jettons.A.metadata.name + ': ' + DeLabUtils.fromNanoToString(farm.balanceStaker)
                                                                        : 'Balance ' + jettons.A.metadata.name + ': ' + DeLabUtils.fromNanoToString(jettons.A.balanceJetton), children: _jsx(Input, { value: valueStake, type: "number", placeholder: '0', onChange: e => setValueStake(e.target.value) }) }), _jsx(FormItem, { children: _jsx(Button, { stretched: true, disabled: !props.DeLabConnector.address
                                                                            || Number(valueStake) < 1
                                                                            || Number(valueStake)
                                                                                > (DeLabUtils.fromNanoToCoin(jettons.A.balanceJetton)), size: "l", onClick: () => {
                                                                            if (type === 0) {
                                                                                stakeFarm(jettons.A.addressWallet);
                                                                            }
                                                                            else {
                                                                                // unstakeFarm(jettons.L.addressWallet)
                                                                                unstakeFarm();
                                                                            }
                                                                        }, children: type === 0 ? 'Stake' : 'Unstake' }) })] }) })] })] })
                                    : null] }) })
                    : _jsx(Div, { children: _jsxs("div", { className: "delab-header-block", style: { display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }, children: [_jsx(Link, { to: "/farms", children: _jsx(IconButton, { children: _jsx(Icon28ChevronLeftOutline, {}) }) }), _jsx(Header, { children: "Error" })] }) }) })] }));
};
