import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Card, Div, SimpleCell, Spinner } from '@vkontakte/vkui';
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import { DeLabUtils } from '../../logic/utils';
export const FarmBlock = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    const [farm, setFarm] = React.useState(undefined);
    const [jettons, setJetton] = React.useState(undefined);
    const data = useLocation();
    const isDesktop = window.innerWidth >= 1000;
    const options = {
        addressContract: props.idFarm ?? '',
        addressUser: props.DeLabConnector.address ?? 'EQC8cR5IkQCsu9Alk1r3K9b2CwR22Eh_zzYqqRSnzpd3oEG0',
        rpcurl: process.env.RPC_URL ?? ''
    };
    async function getWalletAddress(addressJetton) {
        const optionsJetton = {
            addressContract: addressJetton ?? '',
            addressUser: props.DeLabConnector.address ?? 'EQC8cR5IkQCsu9Alk1r3K9b2CwR22Eh_zzYqqRSnzpd3oEG0',
            rpcurl: process.env.RPC_URL ?? ''
        };
        const jettonWallet = DeLabUtils.unwrap(await new DeLabUtils(optionsJetton).fetch());
        return jettonWallet;
    }
    async function getDataFarm() {
        const FarmUtils = DeLabUtils.unwrap(await new DeLabUtils(options).fetchFarm());
        if (FarmUtils) {
            if (await FarmUtils.uninitializedAddress(FarmUtils.addressContract)) {
                if (FarmUtils.addressesFarm === undefined) {
                    setFarm(undefined);
                    return;
                }
                const jettonsProm = [
                    getWalletAddress(FarmUtils.addressesFarm[0]),
                    getWalletAddress(FarmUtils.addressesFarm[1])
                ];
                const jettonsAll = await Promise.all(jettonsProm);
                if (!jettonsAll[0] || !jettonsAll[1]) {
                    setFarm(undefined);
                    return;
                }
                const jettons2 = {
                    A: jettonsAll[0],
                    B: jettonsAll[1]
                };
                setJetton(jettons2);
                if (props.setJettons)
                    props.setJettons(jettons2);
                // const blA1 = DeLabUtils.unwrap(await FarmUtils
                //     .getJettonBalanceFromWalletAddress(FarmUtils.addressesFarm[3]))
                // setBlA(Number(0) ?? 0)
                setFarm(FarmUtils);
                if (props.setFarm)
                    props.setFarm(FarmUtils);
                // console.log('jettons', jettons)
                console.log(farm);
            }
            else {
                setFarm(undefined);
            }
        }
    }
    function calcEndedFarm() {
        if (farm && farm.metadata) {
            if (farm.metadata.token_b_balance === 0)
                return 'ended';
            const secToEnd = (((farm.metadata.token_b_balance - farm.metadata.token_b_shared)
                / farm.metadata.reward_amount)
                * farm.metadata.reward_conf);
            if (secToEnd === 0)
                return 'ended';
            const text = moment(Date.now() + (secToEnd * 1000)).endOf('seconds').fromNow();
            return text;
        }
        return '0 days';
    }
    useEffect(() => {
        // if (!firstRender) {
        setFirstRender(true);
        if (data.state) {
            setJetton(data.state.jettons);
            setFarm(data.state.farm);
        }
        else {
            getDataFarm();
        }
        console.log(1);
        // }
    }, [props.id]);
    return (_jsx(Card, { children: jettons && farm && farm.metadata
            ? _jsx(Link, { to: `/farms/${farm.addressContract}`, state: { farm, jettons }, children: isDesktop
                    ? _jsxs(Div, { className: 'delab-farms-block ' + (props.activeLink ? 'delab-active' : 'not-active'), children: [_jsxs("div", { style: { display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }, children: [_jsx(Avatar, { src: jettons.A.metadata.image, size: 44, style: { marginRight: '10px' } }), _jsxs("div", { children: ["Earn ", jettons.B.metadata.name, _jsxs("div", { className: "delab-mute", children: ["Stake ", jettons.A.metadata.name] })] })] }), _jsx("div", { children: _jsxs("div", { children: [_jsxs("div", { className: "delab-mute", children: [jettons.A.metadata.name, " Staked"] }), DeLabUtils.fromNanoToString(farm.balanceStaker)] }) }), _jsx("div", { children: _jsxs("div", { children: [_jsx("div", { className: "delab-mute", children: "Total staked" }), DeLabUtils.fromNanoToString(farm.metadata.token_a_staked), " ", jettons.A.metadata.symbol] }) }), _jsx("div", { children: _jsxs("div", { children: [_jsx("div", { className: "delab-mute", children: "Ends" }), calcEndedFarm()] }) })] })
                    : _jsxs(Div, { className: 'delab-farms-block-mobile ' + (props.activeLink ? 'delab-active' : 'not-active'), children: [_jsxs(SimpleCell, { disabled: true, before: _jsx(Avatar, { src: jettons.A.metadata.image, size: 44, style: { marginRight: '10px' } }), after: `Stake ${jettons.A.metadata.name}`, children: ["Earn ", jettons.B.metadata.name] }), _jsxs(SimpleCell, { disabled: true, after: DeLabUtils.fromNanoToString(farm.balanceStaker), children: [jettons.A.metadata.name, " Staked"] }), _jsx(SimpleCell, { disabled: true, after: `${DeLabUtils.fromNanoToString(farm.metadata.token_a_staked)} ${jettons.A.metadata.symbol}`, children: "Total staked" }), _jsx(SimpleCell, { disabled: true, after: calcEndedFarm(), children: "Ends" })] }) })
            : _jsx("div", { style: { display: 'flex', alignItems: 'center', flexDirection: 'column' }, children: _jsx(Spinner, { size: "large", style: { margin: '20px 0' } }) }) }, 'farmblock' + props.id));
};
