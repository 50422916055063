import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DeLabButton } from '@delab-team/connect';
import { Icon28DoorArrowRightOutline } from '@vkontakte/icons';
import { Button, PanelHeader } from '@vkontakte/vkui';
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Address, Coins } from 'ton3';
import { DeLabUtils } from '../logic/utils';
function truncAddress(address) {
    return `${address.slice(0, 3)}...${address.slice(-3)}`;
}
function balanceString(balance2) {
    return Number(Number(balance2).toFixed(2)).toLocaleString('ru');
}
export const HeaderBlock = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    const [balanceTon, setBalanceTon] = React.useState('0');
    const isDesktop = window.innerWidth >= 1000;
    const options = {
        addressContract: props.DeLabConnector.address ?? '',
        addressUser: props.DeLabConnector.address ?? '',
        rpcurl: process.env.RPC_URL ?? ''
    };
    const UserUtils = new DeLabUtils(options);
    const location = useLocation();
    async function getBalanceTon() {
        const balanceTonUser = DeLabUtils.unwrap(await UserUtils.balanceTon(props.DeLabConnector.address));
        const balanceTonUserCoin = Coins.fromNano(balanceTonUser ?? 0).toString();
        setBalanceTon(balanceString(balanceTonUserCoin));
    }
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
            getBalanceTon();
        }
    }, []);
    useEffect(() => {
    }, [props.DeLabConnector.address]);
    return (_jsx(PanelHeader, { separator: false, className: "delab-header-full delab-header", before: _jsx("img", { src: 'https://ipfs.io/ipfs/bafkreigb3zknvo4tzdqttreowztwt6uxuahgcbmsf66cdtewz4wrc665yq' }), after: _jsx("div", { children: props.DeLabConnector.address
                ? _jsx(Button, { className: 'delab-wallet', size: "l", mode: "secondary", after: _jsx(Icon28DoorArrowRightOutline, { width: 20, height: 20, onClick: () => props.DeLabConnector.disconnect() }), before: isDesktop ? _jsxs("span", { children: [balanceTon, " TON"] }) : null, children: truncAddress(new Address(props.DeLabConnector.address, {
                        bounceable: true,
                        testOnly: process.env.NETWORK === 'testnet'
                    }).toString('base64')) })
                : _jsx(DeLabButton, { DeLabConnectObject: props.DeLabConnector, scheme: 'dark' }) }), children: _jsxs("div", { className: "delab-header-btn", style: { display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginLeft: '10px' }, children: [_jsx(Link, { to: "/", children: _jsx(Button, { size: "l", mode: "tertiary", className: location.pathname === '/' ? 'delab-active' : '', children: "Home" }) }), _jsx(Link, { to: "/farms", children: _jsx(Button, { size: "l", mode: "tertiary", className: location.pathname.indexOf('/farms') > -1 ? 'delab-active' : '', children: "Farms" }) }), _jsx(Link, { to: "/", children: _jsx(Button, { size: "l", mode: "tertiary", className: location.pathname === '/team' ? 'delab-active' : '', children: "Team" }) })] }) }));
};
