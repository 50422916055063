import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { DeLabModal, DeLabConnect } from '@delab-team/connect';
import { QRCodeSVG } from 'qrcode.react';
import { AppRoot, SplitLayout, SplitCol, View, ModalRoot, ModalPage, ModalPageHeader, PanelHeaderButton, Button, Div, Epic, Tabbar, TabbarItem } from '@vkontakte/vkui';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { Icon24Dismiss, Icon28DiamondOutline, Icon28HomeOutline, Icon28Users3Outline } from '@vkontakte/icons';
import { HeaderBlock } from './layout/header';
import '@vkontakte/vkui/dist/vkui.css';
import './style.css';
import { Main } from './pages/main';
import { Farms } from './pages/farms';
import { Farm } from './pages/farms/farm';
const DeLabConnector = new DeLabConnect('https://delab.team', 'DeLab Team', process.env.NETWORK === 'mainnet' ? 'mainnet' : 'testnet');
export const App = () => {
    const [activeModal, setActiveModal] = React.useState(null);
    const [firstRender, setFirstRender] = React.useState(false);
    const [isConnected, setIsConnected] = React.useState(false);
    const [address, setAddress] = React.useState(undefined);
    const [network, setNetwork] = React.useState('mainnet');
    const [typeConnect, setTypeConnect] = React.useState(undefined);
    const [linkWallet, setLinkWallet] = React.useState('');
    const [approveLink, setApproveLink] = React.useState('');
    const isDesktop = window.innerWidth >= 1000;
    const location = useLocation();
    async function sendTransaction(dataTrans) {
        if (typeConnect !== 'tonkeeper') {
            setActiveModal('confirm');
        }
        const dataTx2 = await DeLabConnector.sendTransaction(dataTrans);
        return dataTx2;
    }
    function listenDeLab() {
        DeLabConnector.on('connect', (data) => {
            setIsConnected(true);
            const connectConfig = data.data;
            console.log(connectConfig);
            setAddress(connectConfig.address);
            setTypeConnect(connectConfig.typeConnect);
            setNetwork(connectConfig.network);
            if (connectConfig.typeConnect === 'tonhub') {
                setLinkWallet(process.env.NETWORK === 'mainnet' ? 'ton://connect' : 'ton-test://connect');
            }
            // if (connectConfig.typeConnect === 'tonkeeper') {
            //     setLinkWallet('ton://connect')
            // }
        });
        DeLabConnector.on('disconnect', () => {
            setIsConnected(false);
            setAddress(undefined);
            setTypeConnect(undefined);
            setNetwork('mainnet');
            console.log('disconect');
        });
        DeLabConnector.on('approve-link', (data) => {
            setApproveLink(data.data ?? '');
            setActiveModal('qrcode');
        });
        DeLabConnector.on('error', (data) => {
            console.log('error-> ', data.data);
        });
        DeLabConnector.on('error-transaction', (data) => {
            console.log('error-transaction-> ', data.data);
        });
        DeLabConnector.on('error-toncoinwallet', (data) => {
            console.log('error-toncoinwallet-> ', data.data);
        });
        DeLabConnector.on('error-tonhub', (data) => {
            console.log('error-tonhub-> ', data.data);
        });
        DeLabConnector.on('error-tonkeeper', (data) => {
            console.log('error-tonkeeper-> ', data.data);
        });
        DeLabConnector.loadWallet();
    }
    useEffect(() => {
        if (!firstRender && DeLabConnector) {
            setFirstRender(true);
            listenDeLab();
        }
    }, []);
    const modalRoot = (_jsxs(ModalRoot, { activeModal: activeModal, children: [_jsx(ModalPage, { id: 'qrcode', className: "delab-modal-root delab-modal-root-active", onClose: () => setActiveModal(null), settlingHeight: 100, header: _jsx(ModalPageHeader, { after: !isDesktop && (_jsx(PanelHeaderButton, { onClick: () => setActiveModal(null), children: _jsx(Icon24Dismiss, {}) })), children: "Tonkeeper" }), children: _jsxs(Div, { style: { display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column' }, children: [_jsx("span", { children: "Approve transaction" }), _jsx("br", {}), _jsx(QRCodeSVG, { value: approveLink, className: "delab-qr-test" }), _jsx(Button, { href: approveLink, target: "_blank", size: "l", children: "Open wallet" })] }) }), _jsx(ModalPage, { id: 'confirm', className: "delab-modal-root delab-modal-root-active delab-modal-height", onClose: () => setActiveModal(null), 
                // settlingHeight={100}
                header: _jsx(ModalPageHeader, { after: !isDesktop && (_jsx(PanelHeaderButton, { onClick: () => setActiveModal(null), children: _jsx(Icon24Dismiss, {}) })), children: "Confirm" }), children: _jsxs(Div, { style: { display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column' }, children: [_jsx("span", { children: "Approve transaction in your wallet" }), _jsxs("div", { style: { display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }, children: [_jsx(Button, { size: 'l', stretched: true, mode: "secondary", onClick: () => setActiveModal(null), children: "Close" }), linkWallet !== ''
                                    ? _jsx(Button, { href: linkWallet, target: "_blank", size: "l", stretched: true, style: { marginLeft: 8 }, children: "Open wallet" })
                                    : null] })] }) })] }));
    return (_jsxs(AppRoot, { children: [_jsx(SplitLayout, { className: "delab", style: { justifyContent: 'center' }, modal: modalRoot, 
                // header={isDesktop
                //     && <PanelHeader separator={false} className="delab-header" />
                // }
                header: _jsx(HeaderBlock, { DeLabConnector: DeLabConnector }), children: _jsx(SplitCol, { fixed: true, animate: false, spaced: isDesktop, width: isDesktop ? '900px' : '100%', maxWidth: isDesktop ? '900px' : '100%', children: _jsx(Epic, { activeStory: 'main', tabbar: !isDesktop && (_jsxs(Tabbar, { children: [_jsx(Link, { to: '/', children: _jsx(TabbarItem, { selected: location.pathname === '/', text: "Home", children: _jsx(Icon28HomeOutline, {}) }) }), _jsx(Link, { to: '/farms', children: _jsx(TabbarItem, { selected: location.pathname.indexOf('/farms') > -1, text: "Farms", children: _jsx(Icon28DiamondOutline, {}) }) }), _jsx(Link, { to: '/', children: _jsx(TabbarItem, { selected: location.pathname === '/team', text: "Team", children: _jsx(Icon28Users3Outline, {}) }) })] })), children: _jsx("div", { id: "main", children: _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(View, { activePanel: 'main', id: "view", children: _jsx(Main, { id: "main", DeLabConnector: DeLabConnector }) }) }), _jsx(Route, { path: "/farms/:idfarm", element: _jsx(View, { activePanel: 'farm', id: "view", children: _jsx(Farm, { id: "farm", DeLabConnector: DeLabConnector, sendTransaction: sendTransaction }) }) }), _jsx(Route, { path: "/farms", element: _jsx(View, { activePanel: 'farms', id: "view", children: _jsx(Farms, { id: "farms", DeLabConnector: DeLabConnector }) }) })] }) }) }) }) }), _jsx(DeLabModal, { DeLabConnectObject: DeLabConnector, scheme: 'dark' })] }));
};
