import axios from 'axios';
import { TonClient, Address as TonAddress } from 'ton';
import { Address, BOC, Builder, Coins, HashmapE, Slice } from 'ton3';
async function gteDataApi(url) {
    const data = await axios.get(url);
    return data;
}
const METADATA_KEYS = {
    name: 0x82a3537ff0dbce7eec35d69edc3a189ee6f17d82f353a553f9aa96cb0be3ce89n,
    symbol: 0xb76a7ca153c24671658335bbd08946350ffc621fa1c516e7123095d4ffd5c581n,
    decimals: 0xee80fd2f1e03480e2282363596ee752d7bb27f50776b95086a0279189675923en,
    description: 0xc9046f7a37ad0ea7cee73355984fa5428982f8b37c8f7bcec91f7ac71a7cd104n,
    image: 0x6105d6cc76af400325e94d588ce511be5bfdbb73b437dc51eca43917d7a43e3dn
};
function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
class DeLabUtils {
    constructor(options) {
        this._addressContract = options.addressContract;
        this._addressUser = options.addressUser;
        this._addressWallet = '';
        this._addressStaker = '';
        this._balanceJetton = 0;
        this._balanceStaker = 0;
        this._balanceReward = 0;
        this._metadata = undefined;
        this._addressesFarm = undefined;
        this._client = new TonClient({ endpoint: options.rpcurl, apiKey: process.env.API_KEY });
    }
    get rpcurl() {
        return this._client.parameters.endpoint;
    }
    get addressWallet() {
        return this._addressWallet;
    }
    get addressStaker() {
        return this._addressStaker;
    }
    get balanceJetton() {
        return this._balanceJetton;
    }
    get addressContract() {
        return this._addressContract;
    }
    get addressUser() {
        return this._addressUser;
    }
    get metadata() {
        return this._metadata;
    }
    get addressesFarm() {
        return this._addressesFarm;
    }
    get balanceStaker() {
        return this._balanceStaker;
    }
    get balanceReward() {
        return this._balanceReward;
    }
    async fetch() {
        await this.getJettonWalletAddress();
        await delay(100);
        await this.getJettonBalanceFromWalletAddress();
        await delay(100);
        await this.getJettonData();
        // const promises = [
        //     this.getJettonBalanceFromWalletAddress(),
        //     this.getJettonData()
        // ]
        // await Promise.all(promises)
        return this;
    }
    async fetchFarm() {
        await this.getFarmsAddresses();
        await delay(100);
        await this.getFarmsInfo();
        await delay(100);
        await this.calcStoreByStaker();
        await delay(100);
        await this.calcStoreByStaker();
        await delay(100);
        await this.getStoredDataStaker();
        await delay(100);
        // const promises = [
        //     this.getFarmsAddresses(),
        //     this.getFarmsInfo(),
        //     this.calcStoreByStaker()
        // ]
        // await Promise.all(promises)
        const sliceDataInfo = DeLabUtils.unwrap(await this.getStoredDataStaker());
        console.log('sss', sliceDataInfo);
        if (sliceDataInfo)
            await this.parseStakerInfo(sliceDataInfo);
        return this;
    }
    static unwrap(data, cb) {
        if (data.type) {
            console.error(data);
            if (cb)
                cb(data);
            return undefined;
        }
        return data;
    }
    static addressToBoc(address) {
        const addressO = new Address(address);
        const builder = new Builder().storeAddress(addressO);
        return BOC.toBase64Standard(builder.cell());
    }
    static fromNanoToString(n) {
        // console.log(n)
        return Coins.fromNano(n ?? 0).toString();
    }
    static fromNanoToCoin(n) {
        // console.log(n)
        return Number(Coins.fromNano(n ?? 0).toString());
    }
    async getJettonWalletAddress(addressUser = this._addressUser) {
        const bocAddress = DeLabUtils.addressToBoc(addressUser);
        const result = await this._client.callGetMethodWithError(TonAddress.parseFriendly(this._addressContract).address, 'get_wallet_address', [['tvm.Slice', bocAddress]]);
        if (result.exit_code !== 0) {
            return { type: 'get_wallet_address', exit_code: result.exit_code };
        }
        const addressWallet = Slice.parse(BOC.fromStandard(result.stack[0][1].bytes)).loadAddress();
        if (addressWallet) {
            const addressWalletString = addressWallet.toString('base64', { bounceable: true });
            if (addressUser === this._addressUser)
                this._addressWallet = addressWalletString;
            return addressWalletString;
        }
        return { type: 'get_wallet_address', exit_code: result.exit_code };
    }
    async balanceTon(addressUser = this._addressUser) {
        const result = await this._client.getContractState(TonAddress.parseFriendly(addressUser).address);
        return result.balance.toString();
    }
    async uninitializedAddress(addressWallet = this._addressWallet) {
        const result = await this._client.getContractState(TonAddress.parseFriendly(addressWallet).address);
        return result.state === 'active';
    }
    async getJettonBalanceFromWalletAddress(addressWallet = this._addressWallet) {
        if (await this.uninitializedAddress(addressWallet)) {
            const result = await this._client.callGetMethodWithError(TonAddress.parseFriendly(addressWallet).address, 'get_wallet_data');
            if (result.exit_code !== 0) {
                return { type: 'get_wallet_data', exit_code: result.exit_code };
            }
            const balanceJetton = Number(result.stack[0][1]);
            if (addressWallet === this._addressWallet)
                this._balanceJetton = balanceJetton;
            return String(balanceJetton);
        }
        return String(0);
    }
    async getJettonData(addressContract = this._addressContract) {
        const result = await this._client.callGetMethodWithError(TonAddress.parseFriendly(addressContract).address, 'get_jetton_data');
        if (result.exit_code !== 0) {
            return { type: 'get_jetton_data', exit_code: result.exit_code };
        }
        const content = result.stack[3][1].bytes;
        const bocConent = BOC.fromStandard(content);
        const sliceCell = Slice.parse(bocConent);
        const prefix = sliceCell.loadUint(8);
        if (prefix === 0x01) { // offchain
            const size = sliceCell.bits.length;
            const stringCell = sliceCell.loadBytes(size);
            let urlIpfs = '';
            let str2 = (new TextDecoder('utf-8').decode(stringCell));
            if (str2.indexOf('http') > -1) {
                urlIpfs = str2;
            }
            else if (str2.indexOf('//') > -1) {
                str2 = str2.substring(7, str2.length);
                urlIpfs = `https://ipfs.io/ipfs/${str2}`;
            }
            console.log(urlIpfs);
            const jsonJetton = await gteDataApi(urlIpfs);
            if (jsonJetton.data) {
                if (addressContract === this._addressContract)
                    this._metadata = jsonJetton.data;
                return jsonJetton.data;
            }
            return { type: 'get_jetton_data', exit_code: -199 };
        }
        if (prefix === 0x00) { // onchain
            const deserializers = {
                key: (key) => new Builder()
                    .storeBits(key)
                    .cell()
                    .slice()
                    .preloadBigUint(256),
                value: (value) => value
            };
            const dict = HashmapE.parse(256, sliceCell, { deserializers });
            const metadata = {};
            dict.forEach((k, v) => {
                Object.keys(METADATA_KEYS).forEach((k2, i) => {
                    if (k === Object.values(METADATA_KEYS)[i]) {
                        const vs = v.slice().loadRef().slice();
                        if (vs.loadUint(8) === 0x00) {
                            metadata[k2] = new TextDecoder()
                                .decode(vs.loadBytes(vs.bits.length));
                            if (k2 === 'image') {
                                if (metadata[k2].indexOf('ipfs://') > -1) {
                                    const str2 = metadata[k2].substring(7, metadata[k2].length);
                                    const urlIpfs = `https://ipfs.io/ipfs/${str2}`;
                                    metadata[k2] = urlIpfs;
                                }
                            }
                        }
                    }
                });
            });
            console.log(metadata);
            if (addressContract === this._addressContract)
                this._metadata = metadata;
            return metadata;
        }
        return { type: 'get_jetton_data', exit_code: -99 };
    }
    async getFarmsAddresses(addressContract = this._addressContract) {
        const result = await this._client.callGetMethodWithError(TonAddress.parseFriendly(addressContract).address, 'get_token_addresses');
        if (result.exit_code !== 0) {
            return { type: 'get_token_addresses', exit_code: result.exit_code };
        }
        const st = result.stack;
        console.log(st[0][1]);
        const jettonA = BOC.fromStandard(st[0][1].elements[0].cell.bytes).slice().loadAddress();
        const jettonB = BOC.fromStandard(st[1][1].elements[0].cell.bytes).slice().loadAddress();
        // const jettonL = BOC.fromStandard(st[2][1].elements[0].cell.bytes).slice().loadAddress()
        const jettonAw = BOC.fromStandard(st[0][1].elements[1].cell.bytes).slice().loadAddress();
        if (jettonA && jettonB && jettonAw) {
            const addresses = [
                jettonA.toString('base64', { bounceable: true }),
                jettonB.toString('base64', { bounceable: true }),
                jettonAw.toString('base64', { bounceable: true })
            ];
            if (addressContract === this._addressContract)
                this._addressesFarm = addresses;
            return addresses;
        }
        return { type: 'get_token_addresses', exit_code: result.exit_code };
    }
    async getFarmsInfo(addressContract = this._addressContract) {
        const result = await this._client.callGetMethodWithError(TonAddress.parseFriendly(addressContract).address, 'get_pool_info');
        if (result.exit_code !== 0) {
            return { type: 'get_token_addresses', exit_code: result.exit_code };
        }
        // console.log(result.stack)
        const metadata = {
            inited: Number(result.stack[0][1]),
            reward_amount: Number(result.stack[1][1]),
            last_govno: Number(result.stack[2][1]),
            reward_conf: Number(result.stack[3][1]),
            token_b_balance: Number(result.stack[4][1]),
            token_b_shared: Number(result.stack[5][1]),
            token_b_shared_old: Number(result.stack[6][1]),
            token_a_staked: Number(result.stack[7][1])
        };
        console.log(metadata);
        if (addressContract === this._addressContract)
            this._metadata = metadata;
        return metadata;
    }
    async calcStoreByStaker(addressUser = this._addressUser) {
        const bocAddress = DeLabUtils.addressToBoc(addressUser);
        const result = await this._client.callGetMethodWithError(TonAddress.parseFriendly(this._addressContract).address, 'calc_store_by_staker', [['tvm.Slice', bocAddress]]);
        if (result.exit_code !== 0) {
            return { type: 'calc_store_by_staker', exit_code: result.exit_code };
        }
        const addressStaker = Slice.parse(BOC.fromStandard(result.stack[0][1].bytes)).loadAddress();
        if (addressStaker) {
            const addressStakerString = addressStaker.toString('base64', { bounceable: true });
            if (addressUser === this._addressUser)
                this._addressStaker = addressStakerString;
            console.log('addressStakerString', addressStakerString);
            return addressStakerString;
        }
        return { type: 'calc_store_by_staker', exit_code: result.exit_code };
    }
    async getStoredDataStaker(addressStaker = this._addressStaker) {
        const result = await this._client.callGetMethodWithError(TonAddress.parseFriendly(addressStaker).address, 'get_stored_data');
        if (result.exit_code !== 0) {
            console.error(result);
            return { type: 'get_stored_data', exit_code: result.exit_code };
        }
        const returnedData = result.stack[2][1].bytes;
        console.log('getStoredDataStaker', returnedData);
        return returnedData;
    }
    async parseStakerInfo(sliceData, addressContract = this._addressContract) {
        const result = await this._client.callGetMethodWithError(TonAddress.parseFriendly(addressContract).address, 'parse_staker_info', [['tvm.Slice', sliceData]]);
        if (result.exit_code !== 0) {
            return { type: 'get_stored_data', exit_code: result.exit_code };
        }
        const infoStaker = result.stack;
        console.log(infoStaker);
        this._balanceStaker = Number(infoStaker[1][1]);
        const stamp = Number(infoStaker[0][1]);
        console.log('stamp', stamp);
        const actualFees = this._metadata.token_b_shared - stamp;
        console.log('actualFees', actualFees);
        this._balanceReward = this._metadata.token_a_staked ? (actualFees * this._balanceStaker) / this._metadata.token_a_staked : 0;
        console.log('_balanceReward', this._balanceReward);
        console.log(this._balanceStaker);
        return '';
    }
}
export { DeLabUtils };
