import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { WebviewType, AdaptivityProvider, ConfigProvider, IOS } from '@vkontakte/vkui';
import { App } from './App';
const el = document.createElement('div');
document.body.appendChild(el);
// eruda.init({
//     container: el,
//     tool: [ 'console', 'elements' ]
// })
const ConfigProviderFix = ConfigProvider;
const AdaptivityProviderFix = AdaptivityProvider;
ReactDOM.render(_jsx(BrowserRouter, { basename: '/', children: _jsx(React.StrictMode, { children: _jsx(ConfigProviderFix, { appearance: 'dark', webviewType: WebviewType.INTERNAL, platform: IOS, children: _jsx(AdaptivityProviderFix, { children: _jsx(App, {}) }) }) }) }), document.querySelector('#root'));
