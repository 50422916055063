import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, CardGrid, Div, FormItem, FormLayout, Group, Header, Input, Panel, PanelHeader } from '@vkontakte/vkui';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Address as TonAddress } from 'ton';
import { Icon28Search } from '@vkontakte/icons';
import JSONFARMS from './farms.json';
import JSONFARMSTESTNET from './farms_testnet.json';
import { FarmBlock } from './block';
const farmsList = process.env.NETWORK === 'mainnet' ? JSONFARMS : JSONFARMSTESTNET;
export const Farms = (props) => {
    const [firstRender, setFirstRender] = React.useState(false);
    const [input, setInput] = React.useState('');
    function checkAddress(text) {
        try {
            const address = TonAddress.parseFriendly(text).address.toString();
            return !!address;
        }
        catch (e) {
            return false;
        }
    }
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
            // loadAllFarms()
        }
    }, []);
    return (_jsxs(Panel, { id: props.id, children: [_jsx(PanelHeader, { className: "delab-fix-header" }), _jsx(Group, { children: _jsxs(Div, { children: [_jsx(Header, { children: "Farms" }), _jsx(FormLayout, { children: _jsxs("div", { style: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' }, children: [_jsx(FormItem, { style: { width: '100%' }, children: _jsx(Input, { value: input, placeholder: 'EQBrfPqjUP0QcxyL4b9qDh0j9TsKjcsLQPj17DWYzr2LdRbQ', onChange: (e) => setInput(e.target.value), type: "text" }) }), _jsx(FormItem, { children: _jsx(Link, { to: checkAddress(input) ? `/farms/${input}` : '/farms', children: _jsx(Button, { after: _jsx(Icon28Search, { width: 20, height: 20 }), disabled: !checkAddress(input), children: "Search" }) }) })] }) }), farmsList
                            ? _jsx(CardGrid, { size: "l", children: farmsList.map((data, key) => (_jsx(FarmBlock, { DeLabConnector: props.DeLabConnector, id: key, activeLink: true, idFarm: data.addressFarms }))) })
                            : _jsx("div", { children: "No results" })] }) })] }));
};
